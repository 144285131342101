import * as React from "react";
import { useRive } from '@rive-app/react-canvas';
import graphicAnim from "../images/rive/ccm_animation.riv";

export default function RiveAnim() {
  const { rive, RiveComponent } = useRive({
    src: graphicAnim,
    autoplay: true,
    artBoard: "V2",
    loop: false,
  });

  return (
    <RiveComponent
    />
  );
}

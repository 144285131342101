import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useRef } from "react";
import * as React from "react";

import Button from "../components/button";
import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import InteractiveScroll from "../components/interactive-scroll";
import FullWidthCta from "../components/full-width-cta";
import WhyItMatters from "../components/why-it-matters";
import StaticHero from "../components/static-hero";

import RiveAnim from "../components/rive";

import * as com from "../styles/common.module.scss";
import * as styles from "../styles/index.module.scss";
import * as typ from "../styles/typography.module.scss";
import * as contactFooter from "../styles/contact-us-footer.module.scss";

export const Head = () => {
	return <HeadMeta title="Collaborative Equity Investors" />;
};

const IndexPage = ({ data }) => {
	const philosophyRef = useRef(null);
	const approachRef = useRef(null);
	const storyRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "Our Philosophy", ref: philosophyRef },
		{ label: "Our Approach", ref: approachRef },
		{ label: "Our Story", ref: storyRef },
		{ label: "Footer", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />

			<StaticHero image="Meeting">
			<h1>Maximize your impact -  Today and for generations</h1>
			<p>
			As an outsourced investment office that exists solely to serve the needs of
			foundations and endowments, we understand the responsibility of managing assets
			dedicated to doing good. Our mission is to help you fully realize your investment
			objectives so you can make a positive impact on the world.
			</p>
			</StaticHero>
				<FiftyFifty reverseMobile={false} useRef={philosophyRef}>
					<div className={styles.riveContainer}>
						<RiveAnim />
					</div>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						Our Philosophy
						</div>
						<h2 className={com.browedHeader}>
						A balanced approach to portfolio construction
						</h2>
						<div className={typ.bodyLargeLight}>
						<p>With a long-term outlook and thoughtful approach, we help our clients balance their portfolio to decrease volatility and maximize gifting power over time. </p>
						<p>We balance asset allocation so your portfolio is comprehensive and diverse. We balance risk to support your aspirational goals while meeting short-term essential obligations.</p>
						</div>
						<Button to="/approach" type="secondary" className={com.leftAlignedButton}>
							Learn How
						</Button>
					</div>
				</FiftyFifty>

				<WhyItMatters />

				<FiftyFifty reverseMobile={false} useRef={approachRef}>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						Our Approach
						</div>
						<h2 className={com.browedHeader}>
						We provide total portfolio management, including:
						</h2>
						<div className={typ.bodyLargeLight}>
							<ul>
								<li>Asset allocation and rebalancing</li>
								<li>Manager due diligence and monitoring across both public and private landscapes</li>
								<li>Risk and liquidity management</li>
								<li>Opportunistic use of both passive and active management strategies</li>
							</ul>
						</div>
						<Button to="/approach" type="secondary" className={com.leftAlignedButton}>
							Learn How
						</Button>
					</div>
					<StaticImage
						src="../images/ccm-approach.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						className={com.imageClipped}
						quality={85}
					/>
				</FiftyFifty>

				<FullWidthCta useRef={contactRef}/>

				<FiftyFifty reverseMobile={true} useRef={storyRef}>
					<StaticImage
						src="../images/ccm-our-story.jpg"
						alt="First Security Building"
						aspectRatio={1 / 1}
						layout="constrained"
						width={640}
						height={640}
						quality={85}
					/>
					<div className="extra-gap">
						<div className={typ.eyebrow}>
						our story
						</div>
						<h2 className={com.browedHeader}>
						A rich history of success
						</h2>
						<div className={typ.bodyLargeLight}>
						Originally created to manage the foundations and trusts of multiple branches of the Eccles family,
						Cynosure now serves many other like-minded individuals, institutions, and multi-generational families.
						With our rich history and commitment to values-driven organizations, we're an ideal partner to help you
						realize your long-term vision.
						</div>
						<Button to="/our-history/" type="secondary" className={com.leftAlignedButton}>
							Our History
						</Button>
					</div>
				</FiftyFifty>

				<ContactUsFooter
				ref={contactRef}
				backdrop={
					<StaticImage
						className={contactFooter.image}
						src={`../images/ccm-footer-2.png`}
						alt=""
						aria-hidden="true"
						layout="fullWidth"
						quality={85}
					/>
				}/>

		</Frame>
	);
};

export default IndexPage;

export const query = graphql`
	query HomePageQuery {
		newsArticles: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/src/cms/data/news/" } }
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			nodes {
				id
				frontmatter {
					title
					date(formatString: "MMMM Do YYYY")
					tags
				}
				fields {
					url
				}
				excerpt(pruneLength: 128)
			}
		}
	}
`;

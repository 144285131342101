import { hasNames } from "../utils";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { forwardRef } from "react";

import Button from "../components/button";

import * as styles from "../styles/full-width-cta.module.scss";
import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";

const FullWidthCta = forwardRef((props, ref) => {
	return (
		<div className={hasNames(styles.FullWidthCta)} ref={ref}>
			<StaticImage
				src={`../images/our-partnerships-section.jpg`}
				alt="our team"
				loading="eager"
				aria-hidden="true"
				placeholder="blurred"
				layout="fullWidth"
				quality={85}
			/>
			<div className={hasNames(styles.FullWidthCtaWrapper, grd.gridContainer)}>
				<div className={hasNames(grd.col6, grd.colMd12)}>
					<div className={typ.eyebrow}>
					Our team
					</div>
					<h2 className={com.browedHeader}>
					Your Mission. Our Expertise.
					</h2>
					<div className={typ.bodyLargeLight}>
					<p>Cynosure thrives on collaboration. When you work with us, you gain access to our whole team of highly experienced investment professionals. Working across disciplines, we bring a wealth of experience and insight to help meet your organization’s short- and long-term objectives.</p>
					</div>
					<Button to="/team" type="primary" className={com.leftAlignedButton}>
					Meet the Team
					</Button>
				</div>
			</div>
		</div>
	);
});

export default FullWidthCta;
